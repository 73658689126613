import { parse } from 'query-string';
import Cookies from 'universal-cookie';
import setCookieExpiration from './src/helpers/setCookieExpiration';
import {
  appendThirdPartyScriptsAsync,
  appendThirdPartyScriptsSync,
  handleScriptEqualWeb,
} from './src/helpers/thirdPartyScripts';

export const onInitialClientRender = () => {
  setCookieTracking();
  appendThirdPartyScriptsSync();

  if (process.env.NODE_ENV === 'production') {
    window.addEventListener('keydown', appendThirdPartyScriptsAsync, {
      once: true,
    });
    window.addEventListener('pointerover', appendThirdPartyScriptsAsync, {
      once: true,
    });
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (process.env.NODE_ENV === 'production') {
    handleScriptEqualWeb({
      pathname: location.pathname,
      prevPathname: prevLocation?.pathname,
    });
  }
};

const setCookieTracking = () => {
  let paramsFilteredHash = {};
  let paramsFilteredSearch = {};
  const cookies = new Cookies();

  if (window?.location?.hash) {
    const queryParamsHash = parse(window.location.hash);
    for (const param in queryParamsHash) {
      if (whitelistQueryParamsHash[param]) {
        paramsFilteredHash = {
          ...paramsFilteredHash,
          [param]: queryParamsHash[param],
        };
      }
    }
  }

  if (window?.location?.search) {
    const queryParamsSearch = parse(window.location.search);
    for (const param in queryParamsSearch) {
      if (whitelistQueryParamsSearch[param]) {
        paramsFilteredSearch = {
          ...paramsFilteredSearch,
          [param]: queryParamsSearch[param],
        };
      }
    }
  }

  if (Object.keys(paramsFilteredHash).length !== 0 || Object.keys(paramsFilteredSearch).length !== 0) {
    const dateExpiry = setCookieExpiration(30);
    cookies.set(
      'queryParamsSitePartners',
      {
        hash: paramsFilteredHash,
        search: paramsFilteredSearch,
        dateSet: new Date(),
      },
      {
        expires: dateExpiry,
      },
    );
  }
};

const whitelistQueryParamsHash = {
  email: 'email',
  referral: 'referral',
};

const whitelistQueryParamsSearch = {
  gclid: 'gclid',
  utm_source: 'utm_source',
  utm_medium: 'utm_medium',
  utm_campaign: 'utm_campaign',
  utm_content: 'utm_content',
  irgwc: 'irgwc',
  irclickid: 'irclickid',
  campaign_id: 'campaign_id',
  af_channel: 'af_channel',
  adset_id: 'adset_id',
  GBRAID: 'GBRAID',
  WBRAID: 'WBRAID',
  af_sub1: 'af_sub1',
  af_sub2: 'af_sub2',
  af_sub3: 'af_sub3',
  af_c_id: 'af_c_id',
  ranMID: 'ranMID',
  pid: 'pid',
  ranSiteID: 'ranSiteID',
  af_click_lookback: 'af_click_lookback',
  af_adset: 'af_adset',
  af_adset_id: 'af_adset',
  ranEAID: 'ranEAID',
  clickid: 'clickid',
  shortlink: 'shortlink',
  deep_link_value: 'deep_link_value',
  c: 'c',
};
