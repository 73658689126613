const setCookieExpiration = (daysToExpire: number): Date => {
  const dateCurrent = new Date()
  const dateExpiry = new Date(
    dateCurrent.getFullYear(),
    dateCurrent.getMonth(),
    dateCurrent.getDate() + daysToExpire,
  )
  return dateExpiry
}

export default setCookieExpiration
