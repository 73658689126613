const scripts = {
  equalWeb: (locale) => ({
    __html: `window.interdeal = { "sitekey": "dd4f94788601d746ef6b03e10c0ceafd", "Position": "Left", "Menulang": '${locale}', "domains": { "js": "https://cdn.equalweb.com/", "acc": "https://access.equalweb.com/" }, "btnStyle": { "vPosition": [ "80%", null ], "scale": [ "0.8", "0.8" ], "color": { "main": "#1876c9", "second": "" }, "icon": { "type": 7, "shape": "semicircle", "outline": false } } }; (function(doc, head, body){ var coreCall = doc.createElement('script'); coreCall.setAttribute('id', 'equalweb-script'); coreCall.src = interdeal.domains.js + 'core/4.5.6/accessibility.js'; coreCall.defer = true; coreCall.integrity = 'sha512-rotepgID7jK/CgENxVXOfMA1+62xwcnONU14mNlHVkywU+O9+uN5JqPHxBCJpGJhc3eUFYdtdjRaTdf6uffOyg=='; coreCall.crossOrigin = 'anonymous'; coreCall.setAttribute('data-cfasync', true ); body? body.appendChild(coreCall) : head.appendChild(coreCall); })(document, document.head, document.body);`,
  }),
  tagmanager: {
    __html: `(function (w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : ''
      j.async = true
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      f.parentNode.insertBefore(j, f)
    })(window, document, 'script', 'dataLayer', 'GTM-KGDFZD8')`,
  },
  segment: {
    __html: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${process.env.GATSBY_SEGMENT_WRITE_KEY}";analytics.SNIPPET_VERSION="4.15.2";
    analytics.load("${process.env.GATSBY_SEGMENT_WRITE_KEY}");
    }}();`,
  },
};

export default scripts;

export const appendThirdPartyScriptsSync = () => {
  appendScriptTagManager();
  appendScript(scripts.segment.__html);
};

const appendScriptEqualWeb = (locale) => {
  const scriptElement = document.createElement('script');
  scriptElement.setAttribute('id', 'equalweb-load-script');
  scriptElement.innerHTML = scripts.equalWeb(locale).__html;
  document.head.appendChild(scriptElement);
};

const appendScriptTagManager = () => {
  const elementNoscript = document.createElement('noscript');
  const elementIframe = document.createElement('iframe');
  elementIframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-KGDFZD8';
  elementIframe.height = '0';
  elementIframe.width = '0';
  elementIframe.style = 'display:none;visibility:hidden';
  elementNoscript.appendChild(elementIframe);

  appendScript(scripts.tagmanager.__html);
  document.body.appendChild(elementNoscript);
};

export const handleScriptEqualWeb = ({ pathname, prevPathname }) => {
  if (!prevPathname) {
    // No previous path, append english or french depending on locale
    appendScriptEqualWeb(!pathname.includes('/fr') ? 'EN' : 'FR');
  } else {
    const isEnToFr = !prevPathname?.includes('fr') && pathname.includes('fr');
    const isFrToEn = prevPathname?.includes('fr') && !pathname.includes('fr');

    // If locale is switched, remove equalweb
    if (isEnToFr || isFrToEn) {
      delete (window as any).interdeal;
      document?.getElementById('equalweb-script')?.remove();
      document?.getElementById('equalweb-load-script')?.remove();
    }

    // After previous language removed, append current language
    if (isEnToFr) {
      appendScriptEqualWeb('FR');
    } else if (isFrToEn) {
      appendScriptEqualWeb('EN');
    }
  }
};

export const appendThirdPartyScriptsAsync = () => {
  window.removeEventListener('keydown', appendThirdPartyScriptsAsync);
  window.removeEventListener('pointerover', appendThirdPartyScriptsAsync);
};

export const appendScript = (scriptInnerHTML) => {
  const scriptElement = document.createElement('script');
  scriptElement.innerHTML = scriptInnerHTML;
  document.head.appendChild(scriptElement);
};
